body {
  background-color: #d9d9d6 !important;
}
.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.MuiTablePagination-root {
  /* Class generated from your code */
  display: none !important;
}
.MuiDataGrid-footer {
  display: none !important;
}
.total-label {
  margin-right: 5rem !important;
}
.MuiInputBase-input.Mui-disabled {
  font-weight: 400 !important;
  color: black !important;
}

.SelectedWeekExample .DayPicker-Month {
  border-collapse: separate;
}
.SelectedWeekExample .DayPicker-WeekNumber {
  outline: none;
}
.SelectedWeekExample .DayPicker-Day {
  outline: none;
  border: 1px solid transparent;
}
.SelectedWeekExample .DayPicker-Day--hoverRange {
  background-color: #efefef !important;
}

.SelectedWeekExample .DayPicker-Day--selectedRange {
  background-color: #fff7ba !important;
  border-top-color: #ffeb3b;
  border-bottom-color: #ffeb3b;
  border-left-color: #fff7ba;
  border-right-color: #fff7ba;
}

.SelectedWeekExample .DayPicker-Day--selectedRangeStart {
  background-color: #ffeb3b !important;
  border-left: 1px solid #ffeb3b;
}

.SelectedWeekExample .DayPicker-Day--selectedRangeEnd {
  background-color: #ffeb3b !important;
  border-right: 1px solid #ffeb3b;
}

.SelectedWeekExample
  .DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
.SelectedWeekExample
  .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
  border-radius: 0 !important;
  color: black !important;
}
.SelectedWeekExample .DayPicker-Day--hoverRange:hover {
  border-radius: 0 !important;
}
.chart-bar {
  width: 604px !important;
  height: 269px !important;
  background-color: white;
}
.chart-line {
  width: 700px !important;
  height: 395px !important;
  background-color: white;
}
.chart-line-customrange {
  width: 950px !important;
  height: 300px !important;
}
.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  animation: spin 2s linear infinite;
}
.chart-js-custom-range {
  background-color: white !important;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.logo {
  /* width: 750px;
  height: 70px; */
  height: 200px;
}
